import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "./AppBar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const HomeLayout = () => {
  const { user } = useAuth();
  const outlet = useOutlet();

  if (user) {
    return <Navigate to="/dashboard/chooseGame" replace />;
  }

  return (
    <>
      <div>
        <AppBar
          pages={[
            { label: "Login", path: "/login" }
          ]}
        />
        {outlet}
        <ToastContainer />
      </div>
    </>

  );
};
